/* global css */
a {
  text-decoration: none;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorBlack.MuiTab-fullWidth.Mui-selected {
  color: #ffff !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorBlack.Mui-selected {
  color: #ffff !important;
}

.nameEllips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.mx3 {
  max-width: 300px;
}
.primary {
  color: #1179c4;
}
.inner_title {
  font-size: 20px;
}
button.MuiButton-root.MuiButton-contained,
button.MuiButton-root.MuiButton-outlined {
  border-radius: 5px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
  border-radius: 5px;
}
.quill .ql-toolbar.ql-snow {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.quill .ql-container.ql-snow {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.dragNdrop_box {
  border-radius: 5px !important;
}
label .MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  border-radius: 5px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  /* box-shadow: 0px 9px 16px rgb(159 162 191 / 12%), 0px 2px 2px rgb(159 162 191 / 0%); */
  /* margin-bottom: 16px; */
  /* box-shadow: 0px 9px 16px rgb(159 162 191 / 18%), 0px 2px 2px rgb(159 162 191 / 0%); */
  /* box-shadow: 0px 0px 1px rgb(159 162 191), 0px 0px 3px rgb(159 162 191 / 75%); */
  /* box-shadow: 0px 0px 5px rgb(232 234 238), 0px 0px 5px rgb(232 234 238); */
  box-shadow: none;
  border: 1px solid #e8eaee;
}
.MuiTabs-scroller.MuiTabs-fixed button.Mui-selected {
  color: #fff;
}
.standard_tab {
  min-width: 200px !important;
}
.search_padding {
  padding: 18px !important;
  /* padding: 0px !important; */
}
.search_bottom_space {
  margin-bottom: 18px;
  /* margin-bottom: 0px; */
}
.contnet_card .MuiButtonBase-root.MuiCardActionArea-root div {
  width: 100%;
}
.contnet_card .content-img {
  padding: 0 !important;
  width: 100% !important;
  height: 150px !important;
  object-fit: cover !important;
  border-radius: 5% 5% 0 0;
}
/* .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart{
  height: 45px;
} */

.sc-gLDzan.cqQwrw.rec.rec-dot.rec.rec-dot_active {
  background-color: #227fc7;
}
.sc-beqWaB.cvyJEp.rec.rec-arrow.rec.rec-arrow-up:hover:enabled {
  background-color: #227fc7;
}
.sc-beqWaB.cvyJEp.rec.rec-arrow.rec.rec-arrow-up:focus:enabled {
  background-color: #227fc7;
}
.content_left_right_spacing {
  padding-left: 18px;
  padding-right: 18px;
}
.MuiTabPanel-root {
  padding-top: 9px !important;
}
.table_pagination_spacing {
  padding: 9px;
}
.content_bottom_space {
  margin-bottom: 27px;
}
.content_top_space {
  margin-top: 27px;
}
.inner_title_bottom_space {
  margin-bottom: 9px !important;
}
.content_block_space {
  padding: 18px !important;
}
.deal_timeline_block
  .MuiTimelineDot-root.MuiTimelineDot-filled.MuiTimelineDot-filledGrey {
  left: 50%;
  transform: translate(-50%, 0px);
}
.content_playbook_title {
  font-size: 14px !important;
}
/* .content_playbook_title a {
  color: #000 !important;
} */

/* .inner_tab {
  background-color: green;
} */

.inner_tab.Mui-selected {
  background-color: #55ad13 !important;
}

.news_annoucement_slider .rec.rec-arrow.rec.rec-arrow-up {
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}
.news_annoucement_slider .rec.rec-arrow.rec.rec-arrow-down {
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}
.content_playbook_chip {
  height: 18px !important;
}

.content_playbook_chip .MuiChip-label {
  padding-left: 4px;
  padding-right: 4px;
}

.active_req_decline_chip {
  height: 22px !important;
}

.active_req_decline_chip .MuiChip-label {
  padding-left: 7px;
  padding-right: 7px;
}
.deal_stage_setting_input_block
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  text-transform: capitalize;
}

/* BounceButton.css */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animated-bounce {
  animation: bounce 1s infinite;
}